/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: product-internal
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItem } from './orderItem';
import { OrderAttachment } from './orderAttachment';


export interface OrderInput { 
    /**
     * The order id from the sub dealer. If this field is null, the order is created by the dealer directly.
     */
    
    origin_order_id?: number | null;
    /**
     * Indicates, how many times the order should be sent to sap.
     */
    
    quantity: number;
    /**
     * The company name of the order user.
     */
    
    order_user_company_name?: string | null;
    /**
     * The company name of the end user.
     */
    
    end_user_company_name?: string | null;
    /**
     * The company name of the invoice user.
     */
    
    invoice_user_company_name?: string | null;
    /**
     * The company name of the delivery user.
     */
    
    delivery_user_company_name?: string | null;
    /**
     * The customer number of the order user.
     */
    
    order_user_customer_number?: string | null;
    /**
     * The customer number of the end user.
     */
    
    end_user_customer_number?: string | null;
    /**
     * The customer number of the invoice user.
     */
    
    invoice_user_customer_number?: string | null;
    /**
     * The customer number of the delivery user.
     */
    
    delivery_user_customer_number?: string | null;
    /**
     * The crm customer number of the order user.
     */
    
    order_user_crm_customer_number?: string | null;
    /**
     * The crm customer number of the end user.
     */
    
    end_user_crm_customer_number?: string | null;
    /**
     * The crm customer number of the invoice user.
     */
    
    invoice_user_crm_customer_number?: string | null;
    /**
     * The crm customer number of the delivery user.
     */
    
    delivery_user_crm_customer_number?: string | null;
    /**
     * The segment code provided by the dealer.
     */
    
    segment_code?: string | null;
    /**
     * The market code provided by the dealer.
     */
    
    market_code?: string | null;
    /**
     * A list of order items, which should be sent to sap.
     */
    
    order_items?: Array<OrderItem> | null;
    /**
     * All attached files, which should be sent to sap.
     */
    
    order_attachments?: Array<OrderAttachment> | null;
    /**
     * The id of the corresponding project.
     */
    
    project_id: number;
    /**
     * The id of the corresponding cart.
     */
    
    cart_id: number;
    /**
     * The model name of the main product.
     */
    
    model?: string | null;
    /**
     * The price list date of the chosen price list.
     */
    
    price_list_date: Date;
    /**
     * A specific order number chosen by the dealer.
     */
    
    customer_order_number?: string | null;
    /**
     * The division, where the main product belongs to.
     */
    
    division?: string | null;
    /**
     * ISO 4217 currency code.
     */
    
    currency?: string | null;
    /**
     * Conversion rate.
     */
    
    currency_ratio: number;
    /**
     * The symbol of the chosen currency.
     */
    
    currency_symbol?: string | null;
    /**
     * Locale id to format currency.
     */
    
    currency_locale?: string | null;
    /**
     * Defines, how many rounding digits the currency has.
     */
    
    currency_rounding_digits?: number | null;
    /**
     * The project code of the corresponding project.
     */
    
    project_code?: string | null;
    /**
     * Indicates, if the order is a whole sale order.
     */
    
    is_whole_sale_order: boolean;
    /**
     * A wanted delivery date for the order.
     */
    
    delivery_date?: Date | null;
    /**
     * A wanted delivery date for the transport.
     */
    
    delivery_date_transport?: Date | null;
    /**
     * A wanted finished delivery date.
     */
    
    delivery_date_finish?: Date | null;
    /**
     * A specific comment for the order.
     */
    
    comment?: string | null;
    /**
     * The chosen delivery type.
     */
    
    delivery: OrderInputDelivery;
    /**
     * An external id from another system to connect this order with the external system.
     */
    
    external_id?: string | null;
}
export enum OrderInputDelivery {
    LKW = 'LKW',
    Abholung = 'Abholung',
    Bahn = 'Bahn',
    Container = 'Container'
};



