/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: product-internal
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderConfigurationAttribute } from './orderConfigurationAttribute';
import { OrderPackage } from './orderPackage';
import { OrderConfigurationOption } from './orderConfigurationOption';


export interface OrderItem { 
    
    id: number;
    
    cart_id: number;
    
    cart_item_id: string;
    
    name?: string | null;
    
    comment?: string | null;
    
    material_id?: string | null;
    
    sap_type?: string | null;
    
    object_type?: string | null;
    
    product_id?: string | null;
    
    model_id?: string | null;
    
    type: OrderItemType;
    
    short_description?: string | null;
    
    quantity: number;
    
    in_stock_quantity: number;
    
    in_stock: boolean;
    
    cit_material?: string | null;
    
    truck_material?: string | null;
    
    long_description?: string | null;
    
    picture_url?: string | null;
    
    delivery_date?: Date | null;
    
    configuration_json?: string | null;
    
    configuration_options?: Array<OrderConfigurationOption> | null;
    
    configuration_attributes?: Array<OrderConfigurationAttribute> | null;
    
    packages?: Array<OrderPackage> | null;
    
    sap_item_pos?: string | null;
    
    sales_price: number;
    /**
     * Article created by dealer and not subdealer.
     */
    
    is_general_agent_article?: boolean | null;
}
export enum OrderItemType {
    Crane = 'Crane',
    Accessory = 'Accessory',
    Custom = 'Custom',
    TuningCenter = 'TuningCenter',
    Option = 'Option',
    WarrantyExtension = 'WarrantyExtension',
    Contract = 'Contract',
    Package = 'Package',
    MCC = 'MCC'
};



