import { OrderQueue, OrderQueueState } from '../generated';
import { testOrderQueueTransaction } from './orderQueueTransaction';

export const testOrderQueue: OrderQueue = {
  id: 1,
  order_id: 2,
  state: OrderQueueState.InProgress,
  sap_result: 'Test Sap Result',
  client_result: 'Test Client Result',
  order_transaction_ids: [testOrderQueueTransaction],
  order_quantity: 2,
};
