import { OrderHistory } from '../generated';
import { testOrderHistoryItem } from './orderHistoryItem';

export const testOrderHistory: OrderHistory = {
  id: 'Test Id',
  order_date: new Date(),
  project_code: 'Test Project Code',
  project_id: 'Test Project Id',
  order_sent_by: 'Test Order Sent By',
  configurator_order_id: 1,
  customer_number: 'Test Customer Number',
  customer_name: 'Test Customer Name',
  sales_representative_number: 'Test Sales Representative Number',
  sales_representative_name: 'Test Sales Representative Name',
  inside_sales_employee_number: 'Test Sales Employee Number',
  inside_sales_employee_name: 'Test Sales Employee Name',
  end_customer_name: 'Test End Customer Name',
  end_customer_number: 'Test End Cusomter Number',
  consignee_name: 'Test Cosignee Name',
  consignee_number: 'Test Cosignee Number',
  customer_order_number: 'Test Order Number',
  invoice_recipient_number: 'Test Invoice Recipient Number',
  invoice_recipient_name: 'Test Invoice Recipient Name',
  created_by: 'Test Created By',
  modified_on: new Date(),
  order_history_items: [testOrderHistoryItem],
  segment_code: 'Test Segment Code',
  market_code: 'Test Market Code',
  is_new_delay_read: false,
};
