import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  OrderHistoryService,
  OrderQueueService,
  OrderService,
} from './generated';

@NgModule({
  imports: [CommonModule],
  providers: [OrderService, OrderHistoryService, OrderQueueService],
})
export class SalesLibsOrderDataAccessModule {}
