import { Order, OrderActions, OrderDelivery, OrderState } from '../generated';
import { testOrderAttachment } from './orderAttachment';
import { testOrderItem } from './orderItem';

export const testOrder: Order = {
  order_items: [testOrderItem],
  actions: [OrderActions.Edit],
  id: 1,
  order_user_company_name: 'Test Order User Company Name',
  end_user_company_name: 'Test End User Company Name',
  invoice_user_company_name: 'Test Invoice User Company Name',
  delivery_user_company_name: 'Test Delivery User Company Name',
  order_user_customer_number: 'Test Order User Customer Number',
  end_user_customer_number: 'Test End User Customer Number',
  invoice_user_customer_number: 'Test Invoice User Customer Number',
  delivery_user_customer_number: 'Test Delivery User Customer Number',
  order_user_crm_customer_number: 'Test Order User CRM Customer Number',
  end_user_crm_customer_number: 'Test End User CRM Customer Number',
  invoice_user_crm_customer_number: 'Test Invoice User CRM Customer Number',
  delivery_user_crm_customer_number: 'Test Delivery User CRM Customer Number',
  sap_ids: ['Test Id'],
  origin_order_id: 1,
  sap_order_user: 'Test',
  sap_invoice_user: 'Test',
  created_by: 'Test',
  project_id: 1,
  cart_id: 1,
  model: 'Test Model',
  price_list_date: new Date(),
  customer_order_number: 'Test',
  delivery: OrderDelivery.LKW,
  created_on: new Date(),
  project_code: 'Test',
  is_whole_sale_order: false,
  delivery_date: new Date(),
  delivery_date_transport: new Date(),
  delivery_date_finish: new Date(),
  comment: 'Test Comment',
  state: OrderState.InProgress,
  currency: 'Test',
  currency_ratio: 1,
  external_id: 'test external',
  currency_symbol: 'Test',
  currency_locale: 'Test',
  currency_rounding_digits: 1,
  order_attachments: [testOrderAttachment],
  market_code: 'test market code',
  segment_code: 'test segment code',
  area_bpm_id: 1,
  quantity: 1,
};
