import { OrderConfigurationOption } from '../generated';

export const testOrderConfigurationOption: OrderConfigurationOption = {
  id: 1,
  name: 'Test OrderConfigurationOption Name',
  description: 'Test OrderConfigurationOption Description',
  technical_description: 'Test OrderConfigurationOption Description',
  cart_item_id: 'Test Cart Item Id',
  cart_config_option_id: 3,
  picture_url: 'Test OrderConfigurationOption Picture Url',
  value: 'Test OrderConfigurationOption Value',
  value_description: 'Test OrderConfigurationOption Value Description',
  sales_price: 4,
  sort_key: 100,
};
