import { OrderAttachment } from '../generated';

export const testOrderAttachment: OrderAttachment = {
  id: 1,
  order_id: 1,
  name: 'Test Name',
  file_url: 'Test File Url',
  dealer_upload: false,
  forward_to_palfinger: false,
};
