import { OrderItem, OrderItemType } from '../generated';
import { testOrderConfigurationOption } from './orderConfigurationOption';

export const testOrderItem: OrderItem = {
  sap_type: 'Test',
  id: 1,
  cart_id: 2,
  cart_item_id: 'Test Cart Item Id',
  name: 'Test Name',
  comment: 'Test Comment',
  material_id: 'Test Material Id',
  object_type: 'Test Object Type',
  product_id: 'Test Product Id',
  model_id: 'Test Model Id',
  type: OrderItemType.Crane,
  short_description: 'Test Short Description',
  quantity: 4,
  in_stock_quantity: 5,
  in_stock: false,
  long_description: 'Test Long Description',
  picture_url: 'Test Picture Url',
  sales_price: 6,
  delivery_date: new Date(),
  configuration_options: [testOrderConfigurationOption],
  sap_item_pos: 'Test Sap Item Position',
};
