import {
  OrderHistoryItem,
  OrderHistoryItemOrderType,
  OrderHistoryItemState,
} from '../generated';

export const testOrderHistoryItem: OrderHistoryItem = {
  id: 'Test Id',
  position_number: 'Test Position Number',
  order_sap_id: 'Test Order Sap Id',
  material_id: 'Test Material Id',
  quantity: 1,
  object_type: 'Test Object Type',
  aok_date: new Date(),
  order_date: new Date(),
  first_ack_date: new Date(),
  equipment_number: 'Test Equipment Number',
  product_name: 'Test Product Name',
  act_ack_date: new Date(),
  consignee_number: 'Test Cosignee Number',
  consignee_name: 'Test Cosignee Name',
  state: OrderHistoryItemState.NewPlanned,
  order_type: OrderHistoryItemOrderType.CCO,
  last_changed: new Date(),
};
