/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: product-internal
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DocumentDownload } from '../model/documentDownload';
// @ts-ignore
import { OrderHistory } from '../model/orderHistory';
// @ts-ignore
import { OrderHistoryContainer } from '../model/orderHistoryContainer';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class OrderHistoryService {

    protected basePath = 'provide_injection_token_for_OrderHistoryService';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Create OrderHistory excel table, saves it in Azure storage and return link to it
     * @param searchTerm 
     * @param states 
     * @param equipmentTypes 001 &#x3D;&gt; Crane, 002 &#x3D;&gt; Railway
     * @param deliveryTimeType 
     * @param newDeliveryUnseen 
     * @param orderDateFrom The start date for the order period.
     * @param orderDateTo The end date for the order period.
     * @param deliveryDateFrom The start date for the current delivery period.
     * @param deliveryDateTo The end date for the current delivery period.
     * @param sortField 
     * @param sortDirection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportOrdersHistoryTable(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<DocumentDownload>;
    public exportOrdersHistoryTable(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<DocumentDownload>>;
    public exportOrdersHistoryTable(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<DocumentDownload>>;
    public exportOrdersHistoryTable(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchTerm !== undefined && searchTerm !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchTerm, 'SearchTerm');
        }
        if (states) {
            states.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'States');
            })
        }
        if (equipmentTypes) {
            equipmentTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'EquipmentTypes');
            })
        }
        if (deliveryTimeType !== undefined && deliveryTimeType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveryTimeType, 'DeliveryTimeType');
        }
        if (newDeliveryUnseen !== undefined && newDeliveryUnseen !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>newDeliveryUnseen, 'NewDeliveryUnseen');
        }
        if (orderDateFrom !== undefined && orderDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDateFrom, 'OrderDateFrom');
        }
        if (orderDateTo !== undefined && orderDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDateTo, 'OrderDateTo');
        }
        if (deliveryDateFrom !== undefined && deliveryDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveryDateFrom, 'DeliveryDateFrom');
        }
        if (deliveryDateTo !== undefined && deliveryDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveryDateTo, 'DeliveryDateTo');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'SortField');
        }
        if (sortDirection !== undefined && sortDirection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDirection, 'SortDirection');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<DocumentDownload>(`${this.configuration.basePath}/OrderHistory/search/export`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAvailableEquiTypes(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<Array<string>>;
    public getAvailableEquiTypes(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<Array<string>>>;
    public getAvailableEquiTypes(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<Array<string>>>;
    public getAvailableEquiTypes(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<string>>(`${this.configuration.basePath}/OrderHistory/equipment_types`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get a Order Confirmation Document Link
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderConfirmationDocumentLink(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<string>;
    public getOrderConfirmationDocumentLink(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<string>>;
    public getOrderConfirmationDocumentLink(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<string>>;
    public getOrderConfirmationDocumentLink(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrderConfirmationDocumentLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/OrderHistory/${encodeURIComponent(String(id))}/order_confirmation_link`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderHistoryBySapId(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<OrderHistory>;
    public getOrderHistoryBySapId(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<OrderHistory>>;
    public getOrderHistoryBySapId(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<OrderHistory>>;
    public getOrderHistoryBySapId(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOrderHistoryBySapId.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<OrderHistory>(`${this.configuration.basePath}/OrderHistory/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param searchTerm 
     * @param states 
     * @param equipmentTypes 001 &#x3D;&gt; Crane, 002 &#x3D;&gt; Railway
     * @param deliveryTimeType 
     * @param newDeliveryUnseen 
     * @param orderDateFrom The start date for the order period.
     * @param orderDateTo The end date for the order period.
     * @param deliveryDateFrom The start date for the current delivery period.
     * @param deliveryDateTo The end date for the current delivery period.
     * @param sortField 
     * @param sortDirection 
     * @param pageSize 
     * @param pageIndex 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOrderHistory(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, pageSize?: number, pageIndex?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<OrderHistoryContainer>;
    public searchOrderHistory(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, pageSize?: number, pageIndex?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<OrderHistoryContainer>>;
    public searchOrderHistory(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, pageSize?: number, pageIndex?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<OrderHistoryContainer>>;
    public searchOrderHistory(searchTerm?: string, states?: Array<'Undefined' | 'NewPlanned' | 'ProductionStarted' | 'ProductionFinished' | 'Delivered' | 'Cancelled'>, equipmentTypes?: Array<string>, deliveryTimeType?: 0 | 1, newDeliveryUnseen?: boolean, orderDateFrom?: Date, orderDateTo?: Date, deliveryDateFrom?: Date, deliveryDateTo?: Date, sortField?: string, sortDirection?: string, pageSize?: number, pageIndex?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (searchTerm !== undefined && searchTerm !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchTerm, 'SearchTerm');
        }
        if (states) {
            states.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'States');
            })
        }
        if (equipmentTypes) {
            equipmentTypes.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'EquipmentTypes');
            })
        }
        if (deliveryTimeType !== undefined && deliveryTimeType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveryTimeType, 'DeliveryTimeType');
        }
        if (newDeliveryUnseen !== undefined && newDeliveryUnseen !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>newDeliveryUnseen, 'NewDeliveryUnseen');
        }
        if (orderDateFrom !== undefined && orderDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDateFrom, 'OrderDateFrom');
        }
        if (orderDateTo !== undefined && orderDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>orderDateTo, 'OrderDateTo');
        }
        if (deliveryDateFrom !== undefined && deliveryDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveryDateFrom, 'DeliveryDateFrom');
        }
        if (deliveryDateTo !== undefined && deliveryDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>deliveryDateTo, 'DeliveryDateTo');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'SortField');
        }
        if (sortDirection !== undefined && sortDirection !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortDirection, 'SortDirection');
        }
        if (pageSize !== undefined && pageSize !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageSize, 'page_size');
        }
        if (pageIndex !== undefined && pageIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>pageIndex, 'page_index');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<OrderHistoryContainer>(`${this.configuration.basePath}/OrderHistory/search`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIsNewDelayRead(requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any>;
    public updateIsNewDelayRead(requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<any>>;
    public updateIsNewDelayRead(requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<any>>;
    public updateIsNewDelayRead(requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling updateIsNewDelayRead.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/OrderHistory/is_new_delay_read`,
            requestBody,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
