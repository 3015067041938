/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: product-internal
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrderHistoryItem { 
    
    id?: string | null;
    
    position_number?: string | null;
    
    order_sap_id?: string | null;
    
    material_id?: string | null;
    
    quantity: number;
    
    object_type?: string | null;
    
    aok_date?: Date | null;
    
    order_date?: Date | null;
    
    first_ack_date?: Date | null;
    
    product_name?: string | null;
    
    equipment_number?: string | null;
    
    act_ack_date?: Date | null;
    
    previous_act_ack_date?: Date | null;
    
    consignee_number?: string | null;
    
    consignee_name?: string | null;
    
    state: OrderHistoryItemState;
    
    order_type: OrderHistoryItemOrderType;
    
    last_changed: Date;
}
export enum OrderHistoryItemState {
    Undefined = 'Undefined',
    NewPlanned = 'NewPlanned',
    ProductionStarted = 'ProductionStarted',
    ProductionFinished = 'ProductionFinished',
    Delivered = 'Delivered',
    Cancelled = 'Cancelled'
};
export enum OrderHistoryItemOrderType {
    CCO = 'CCO',
    STO = 'STO',
    WSO = 'WSO'
};



