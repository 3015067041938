/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: product-internal
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderQueueTransaction } from './orderQueueTransaction';


export interface OrderQueue { 
    
    id: number;
    
    order_id: number;
    
    state: OrderQueueState;
    
    order_transaction_ids?: Array<OrderQueueTransaction> | null;
    
    sap_result?: string | null;
    
    sales_organization?: string | null;
    
    order_quantity: number;
    
    client_result?: string | null;
}
export enum OrderQueueState {
    Processed = 'Processed',
    InProgress = 'InProgress',
    Error = 'Error',
    PartiallySucceeded = 'PartiallySucceeded'
};



